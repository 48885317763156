<template>
  <v-container>
    <court-list/>
  </v-container>
</template>

<script>
import CourtList from "@/components/app_setting/court/CourtList";

export default {
  name: "CourtPage",
  components: {
    CourtList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Court Types'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>